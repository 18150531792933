import { lazy, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Loading from "./components/Loading/Loading";
import { ToastContainer, Slide } from "react-toastify";

const UserRoutes = lazy(() => import("./UserRoutes"));

const App = () => {
  return (
    <>
      <div className="app">
        <Router>
          <ScrollToTop />
          <Suspense fallback={<Loading />}>
            <UserRoutes />
          </Suspense>
        </Router>
        <ToastContainer
          position="top-center"
          transition={Slide}
          autoClose={false}
          hideProgressBar
          pauseOnFocusLoss
          newestOnTop={false}
          closeOnClick
        />
      </div>
    </>
  );
};

export default App;
