import Spinner from "react-bootstrap/Spinner";

const Loading = ({ text = "Loading..." }) => {
  return (
    <div className="d-block text-center mt-4">
      <Spinner animation="border" role="status" />
      <div className="h3 mt-3">{text}</div>
    </div>
  );
};

export default Loading;
